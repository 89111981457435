.search-container {
  border: 1px solid #d9d9d9;
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 20px;
}

.search-container .row-1 {
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.search-container .row-2 {
  position: relative;
}

.drop-down-container {
  position: absolute;
  padding: 5px;
  background-color: #fff;
  border: 2px solid #d9d9d9;
  border-radius: 10px;
  width: 100%;
  top: 6px;
  z-index: 100;
  overflow-y: auto;
  max-height: 300px;
}

.search-drop-down-item {
  padding: 10px;
  font-size: 16px !important;
  cursor: pointer;
}

.search-drop-down-item:hover {
  background-color: #f2f2f2;
  border-radius: 8px;
  font-family: "Montserrat-Medium";
}

.search-icon-container {
  margin-right: 8px;
}
.search-icon-container .search-img {
  height: 22px;
}
.search-input-container {
  padding: 8px 0;
  width: 100%;
}
.text-input {
  width: 90%;
  border: none;
  font-size: 16px !important;
  font-family: "Montserrat-Regular";
}

.text-input:focus {
  border: none;
  outline: none;
}
.clear-icon-container,
.search-icon-container {
  display: flex;
  align-items: center;
}
.clear-img {
  opacity: 0.6;
  cursor: pointer;
  height: 20px;
}
.clear-img:hover {
  opacity: 1;
}

@media screen and (max-width: 425px) {
  .search-input-container {
    padding: 5px 0;
  }
}
