.map-apps-modal__container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.38);
  display: flex;
  align-items: center;
  justify-content: center;
}
.map-apps-modal__content {
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  padding-top: 10px;
  padding-right: 18px;
  padding-left: 18px;
  padding-bottom: 15px;
  border-radius: 12px;
  width: 65%;
}
.map-apps-modal__header {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.map-apps-modal__header-title {
  font-size: 14px;
}
.map-apps-modal__header-close-icon {
  background-color: transparent;
  padding: 0;
  border: none;
}
.map-apps-modal__header-close-icon img {
  height: 25px;
  width: 25px;
  cursor: pointer;
}
.map-apps-modal__app-logos-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.map-apps-modal__app-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 46%;
}
.map-apps-modal__app-logo button {
  background-color: transparent;
  padding: 0;
  border: none;
  height: 60px;
  width: 60px;
}
.map-apps-modal__app-logo button img {
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  height: inherit;
  width: inherit;
}

.map-apps-modal__app-logo .map-app-name {
  font-size: 12px;
  margin-bottom: 0;
  margin-right: 0;
  margin-left: 0;
  margin-top: 8px;
}
.map-apps-modal__preference-container {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
.map-apps-modal__preference-container #app-preference {
  height: 18px;
  width: 18px;
  margin-right: 10px;
}
