@font-face {
  font-family: "Montserrat-Regular";
  src: url("./assets/fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url("./assets/fonts/Montserrat-Medium.ttf");
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url("./assets/fonts/Montserrat-Bold.ttf");
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("./assets/fonts/Montserrat-SemiBold.ttf");
}

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Montserrat-Regular";
  overflow: hidden;
  background-color: #000;
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f7f7f7;
}

.loading-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.magnus-logo-container img {
  height: 8vh;
}

.error-message {
  margin-top: 10px;
}

.svg-loader-img {
  height: 50px;
}

.map-dashboard-wrapper {
  display: flex;
  overflow: hidden;
  position: relative;
}

.map-container {
  background-color: grey;
  width: 74%;
}

#map {
  height: 100%;
}

.desktop-list-wrapper {
  height: 100%;
  width: 26%;
  background-color: #f7f7f7;
}

.map-list-container {
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  overflow-y: auto;
}

.desktop-list-wrapper .desktop-map-list-container {
  width: 100%;
  height: 90%;
  padding-top: 10px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 25px;
}

.desktop-list-wrapper .desktop-search-container {
  margin-top: 20px;
  padding: 0 25px;
}

.mobile-search-container {
  width: 100%;
  padding: 0 7.5%;
}

.mobile-map-list-container {
  width: 100%;
  padding: 0;
  height: 100%;
  align-items: center;
}

.card-container {
  scroll-margin-top: 12px;
  margin-bottom: 20px;
  padding: 10px 15px;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  border-radius: 5px;
}

.selected-card-container {
  border: 2px solid #7c81f6;
}

.card-row {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}

.last-updated {
  font-size: 12px;
  color: #8c8c8c;
}

.bin-type-col {
  width: 35%;
  font-size: 14px;
  color: #777777;
}

.space-info-col {
  font-family: "Montserrat-SemiBold" !important;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 5px;
}

.card-heading-container {
  display: flex;
  justify-content: space-between;
}

.heading {
  font-size: 16px;
  font-family: "Montserrat-Medium" !important;
  margin-right: 10px;
}

.direction-img-container img {
  height: 30px;
  width: 30px;
}

.direction-img-container button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.mobile-view-drawer {
  position: fixed;
  background-color: #f7f7f7;
  max-height: 65%;
  min-height: 35%;
  bottom: 0;
  right: 0;
  left: 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.5);
}

.drawer-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
  margin-bottom: 10px;
  background-color: transparent;
}

/* .drawer-button-container button {
  opacity: 0.5;
  background-color: grey;
  width: 20%;
  border: none;
  height: 8px;
  border-radius: 10px;
} */

.drawer-button-container img {
  height: 30px;
  width: 50px;
}

.arrow-rotation {
  transform: rotate(180deg);
}

.gm-style iframe + div {
  border: none !important;
}

.gm-style .gm-style-iw {
  font-family: "Montserrat-Medium" !important;
}

.gm-ui-hover-effect:focus-visible {
  outline: none !important;
}

.bin-type-indicator {
  height: 12px;
  width: 12px;
  border: 1px solid;
  border-radius: 50%;
  margin-right: 5px;
}

@media screen and (max-width: 768px) {
  .map-container {
    /* width: 65%; */
  }
  .desktop-list-wrapper {
    width: 35%;
  }
  .bin-type-col {
    width: 40%;
  }
}

@media screen and (max-width: 425px) {
  .gmnoprint {
    opacity: 0.65;
  }
  .map-container {
    width: 100%;
    /* height: 68%; */
  }
  .card-container {
    margin-bottom: 20px;
    padding: 10px;
    margin-bottom: 10px;
    width: 85%;
    background-color: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
}
